

<template>
  <div>
    <Pane />

    <div class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model :colon="false"
                        :model="form"
                        layout="inline"
                        @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name"
                       placeholder="标段名称"
                       style="width: 150px"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-select placeholder="状态"
                        v-model="form.status"
                        style="width: 150px">
                <a-select-option v-for="item in statusList"
                                 :key="item.value"
                                 :value="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query"
                          type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right"
               style="margin-top: 4px">
            <a-button v-if="$getPermission($route.path + '/add')"
                      @click="$router.push($route.path + '/add')"
                      type="primary">新增</a-button>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table bordered
               :data-source="list"
               :loading="loading"
               @change="onChange"
               rowKey="id"
               :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }">
        <a-table-column title="标书名称"
                        data-index="name"
                        align="center">
        </a-table-column>
        <a-table-column title="招标状态"
                        align="center">
          <template slot-scope="text">
            <div>
              <DataDictFinder dictType="bid_item_status"
                              :dictValue="text.status" />
            </div>
          </template>
        </a-table-column>
        <a-table-column title="审核状态"
                        data-index="reviewStatus"
                        align="center">
          <template slot-scope="text">
            <div>
              {{getStatus(text)}}
            </div>
          </template>
        </a-table-column>
        <a-table-column title="操作"
                        align="center"
                        width="180px">
          <template slot-scope="text">
            <a-space>
              <a @click.prevent="evaluate(text)"
                 v-if="text.reviewStatus !== 'cancelled'">评标</a>
              <a @click.prevent="publicity(text)">中标公示</a>
              <a>
                <FileTableDialog title="招标文件下载"
                                 :msg="text.fileList">
                  <span class="user">
                    下载
                  </span>
                </FileTableDialog>
              </a>

              <a-dropdown v-if="$getPermission($route.path + '/edit')">
                <a @click="(e) => e.preventDefault()">
                  更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item @click="edit(text)">
                    <span class="link">编辑</span>
                  </a-menu-item>
                  <a-menu-item v-if="text.status !== 'cancelled'"
                               @click="deleteText(text)">
                    <span class="danger">取消</span>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>


<script>
import FileTableDialog from "@/views/bidding/components/file-table-dialog";
import { fetchList, cancel } from "@/api/bidding";
import { mapGetters } from "vuex";

export default {
  components: {
    FileTableDialog,
  },
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isBidManager"]),
    ...mapGetters("auth", ["isBidCommittee"]),

    statusList() {
      return this.findDataDict("bid_item_status");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getStatus(value) {
      switch (value) {
        case "reviewing":
          return "审核中";
        case "cancelled":
          return "已取消";
        case "reviewed":
          return "已审核";
        case "rejected":
          return "已驳回";
      }
      return;
    },

    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.list.forEach((element) => {
              console.log(element.bidFiles);

              let bidFiles = JSON.parse(element.bidFiles || "");
              element.fileList = [
                {
                  name: "招标文件",
                  list: bidFiles,
                },
              ];
            });
            console.log(this.list);

            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    evaluate(text) {
      // 用户是管理员，这条招标信息没有配置过评标委员，那跳转过去配，如果配置过，进入到消息汇总
      // 用户是普通人，点击评标直接去评
      if (this.isBidManager) {
        // 未配置，跳转到评标委员配置页面
        if (text.juryAllocated === 0) {
          this.$router.push(
            this.$route.path + "/evaluation/select-user?id=" + text.id
          );
        } else {
          // 配置过，跳转到消息汇总页面
          this.$router.push(
            this.$route.path + "/evaluation/summary?id=" + text.id
          );
        }
      } else if (this.isBidCommittee) {
        this.$router.push(this.$route.path + "/evaluation?id=" + text.id);
      }
    },

    publicity(text) {
      this.$router.push(this.$route.path + "/publicity?id=" + text.id);
    },

    downloadText(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },

    edit(text) {
      this.$router.push(this.$route.path + "/edit?id=" + text.id);
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要取消吗？",
        onOk() {
          cancel({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;
}
</style>